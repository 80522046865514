import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Main() {
  const navigate = useNavigate();

  const handleAddNewCompany = () => {
    navigate("/add-new-company-in-map");
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center mt-3">
            <h1>Contact Us</h1>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-4">
            <Link
              to={"/view-contact-responses"}
              onClick={handleAddNewCompany}
              style={{ textDecoration: "none" }}
            >
              <div className="card text-center mt-2 p-2 shadow-lg">
                <div className="card-body">
                  <h4 className="card-title">CONTACT DETAILS</h4>
                  <p className="card-text">
                    View the contact responses present in the database.
                  </p>
                  <p style={{ textDecoration: "none", color: "red" }}>
                    Go &rarr;
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center mt-3">
            <h1>Company Map</h1>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-4">
            <Link
              to={"/add-new-company-in-map"}
              onClick={handleAddNewCompany}
              style={{ textDecoration: "none" }}
            >
              <div className="card text-center mt-2 p-2 shadow-lg">
                <div className="card-body">
                  <h4 className="card-title">NEW COMPANY</h4>
                  <p className="card-text">
                    Add a new company to the database under company map section.
                  </p>
                  <p style={{ textDecoration: "none", color: "red" }}>
                    Go &rarr;
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-4">
            <Link
              to={"/view-company-map"}
              onClick={handleAddNewCompany}
              style={{ textDecoration: "none" }}
            >
              <div className="card text-center mt-2 p-2 shadow-lg">
                <div className="card-body">
                  <h4 className="card-title">VIEW COMPANY LIST</h4>
                  <p className="card-text">
                    Get all the companies that are present in the database.
                  </p>
                  <p style={{ textDecoration: "none", color: "red" }}>
                    Go &rarr;
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-4">
            <Link
              to={"/delete-company-map"}
              onClick={handleAddNewCompany}
              style={{ textDecoration: "none" }}
            >
              <div className="card text-center mt-2 p-2 shadow-lg">
                <div className="card-body">
                  <h4 className="card-title">DELETE COMPANY</h4>
                  <p className="card-text">
                    Delete a company from the database under company map
                    section.
                  </p>
                  <p style={{ textDecoration: "none", color: "red" }}>
                    Go &rarr;
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-4">
            <Link
              to={"/update-company-map"}
              onClick={handleAddNewCompany}
              style={{ textDecoration: "none" }}
            >
              <div className="card text-center mt-2 p-2 shadow-lg">
                <div className="card-body">
                  <h4 className="card-title">UPDATE COMPANY</h4>
                  <p className="card-text">
                    Update the details of a company from the database.
                  </p>
                  <p style={{ textDecoration: "none", color: "red" }}>
                    Go &rarr;
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="container mb-5 pb-5">
        <div className="row">
          <div className="col-md-12 text-center mt-5">
            <h1>Blogs</h1>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-4">
            <Link
              to={"/add-new-blog"}
              onClick={handleAddNewCompany}
              style={{ textDecoration: "none" }}
            >
              <div className="card text-center mt-2 p-2 shadow-lg">
                <div className="card-body">
                  <h4 className="card-title">NEW BLOG</h4>
                  <p className="card-text">Add a new blog to the database.</p>
                  <p style={{ textDecoration: "none", color: "red" }}>
                    Go &rarr;
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-4">
            <Link
              to={"/view-blogs"}
              onClick={handleAddNewCompany}
              style={{ textDecoration: "none" }}
            >
              <div className="card text-center mt-2 p-2 shadow-lg">
                <div className="card-body">
                  <h4 className="card-title">VIEW BLOG LIST</h4>
                  <p className="card-text">View all blogs in the database.</p>
                  <p style={{ textDecoration: "none", color: "red" }}>
                    Go &rarr;
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-4">
            <Link
              to={"/delete-blog"}
              onClick={handleAddNewCompany}
              style={{ textDecoration: "none" }}
            >
              <div className="card text-center mt-2 p-2 shadow-lg">
                <div className="card-body">
                  <h4 className="card-title">DELETE BLOG</h4>
                  <p className="card-text">
                    Delete Existing Blog from Database
                  </p>
                  <p style={{ textDecoration: "none", color: "red" }}>
                    Go &rarr;
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
