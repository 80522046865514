import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Realm from "realm-web";

// Import the Captcha component
const Captcha = ({ onCaptchaCheck }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxClick = () => {
    setIsChecked(true);
    onCaptchaCheck(true);
  };

  return (
    <div className="captcha">
      <div className="spinner">
        <label>
          <input
            type="checkbox"
            onClick={handleCheckboxClick}
            disabled={isChecked}
          />
          <span className="checkmark">
            <span>&nbsp;</span>
          </span>
        </label>
      </div>
      <div className="text">I&apos;m not a robot</div>
      <div className="logo">
        <img
          src="https://forum.nox.tv/core/index.php?media/9-recaptcha-png/"
          alt="reCAPTCHA"
        />
        <p>reCAPTCHA</p>
        <small>Privacy - Terms</small>
      </div>
    </div>
  );
};

const LoginPage = () => {
  const [userID, setUserID] = useState("");
  const [password, setPassword] = useState("");
  const [isCaptchaChecked, setIsCaptchaChecked] = useState(false); // State to track captcha check
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!isCaptchaChecked) {
      alert("Please complete the captcha.");
      return;
    }

    const app = new Realm.App({ id: "application-0-dlhopxm" });
    const credentials = Realm.Credentials.anonymous();

    try {
      const user = await app.logIn(credentials);
      const mongo = user.mongoClient("mongodb-atlas");
      const collection = mongo.db("bharatsoft").collection("admin");

      const userData = await collection.findOne({ username: userID });

      if (userData && userData.password === password) {
        const fakeToken = "your-jwt-token";
        localStorage.setItem("jwtToken", fakeToken);
        navigate("/dashboard");
      } else {
        alert("Invalid username or password");
      }
    } catch (err) {
      console.error("Failed to log in", err);
      alert("Login failed. Please try again.");
    }
  };

  const token = localStorage.getItem("jwtToken");

  return (
    <div className="container d-flex flex-column align-items-center justify-content-center vh-100">
      <h2 className="mb-4">Login</h2>

      {token && (
        <button
          className="btn btn-primary mb-4"
          onClick={() => navigate("/dashboard")}
        >
          Go to Dashboard
        </button>
      )}

      <form onSubmit={handleLogin} className="w-50">
        <div className="mb-3">
          <label htmlFor="userID" className="form-label">
            Username:
          </label>
          <input
            type="text"
            className="form-control"
            id="userID"
            value={userID}
            onChange={(e) => setUserID(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Password:
          </label>
          <input
            type="password"
            className="form-control"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        {/* Captcha component */}
        <Captcha onCaptchaCheck={setIsCaptchaChecked} />

        <button type="submit" className="btn btn-success w-100">
          Login
        </button>
      </form>
    </div>
  );
};

export default LoginPage;
