import React, { useState } from "react";
import Navbar from "../Navbar/main";
import * as Realm from "realm-web";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

export default function AddCompany() {
  const [formData, setFormData] = useState({
    companyName: "",
    avgProjectSize: "",
    projectCompleted: "",
    companySize: "",
    visitWebsite: "",
    location: "",
    aboutCompany: "",
    remoteCollaborationPossible: "",
    companyPage: "",
    developmentProcess: "",
    methodologies: [],
    companyImages: [],
    languages: [],
    workTags: [],
    idealClients: "",
    contactPersonName: "",
    contactPersonImage: null,
    phoneNumber: "",
    image: null,
  });

  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // To disable submit button

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle image change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData({ ...formData, image: reader.result });
    };
    reader.readAsDataURL(file);
  };

  // Handle contact person image change
  const handleContactPersonImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData({ ...formData, contactPersonImage: reader.result });
    };
    reader.readAsDataURL(file);
  };

  // Handle company images change
  const handleCompanyImagesChange = (e) => {
    const files = e.target.files;
    const companyImages = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onloadend = () => {
        companyImages.push(reader.result);
        setFormData({ ...formData, companyImages });
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle methodologies change
  const handleMethodologiesChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, methodologies: value.split(",") });
  };

  // Handle languages change
  const handleLanguagesChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, languages: value.split(",") });
  };

  // Handle work tags change
  const handleWorkTagsChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, workTags: value.split(",") });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable submit button

    try {
      const app = new Realm.App({ id: "application-0-dlhopxm" });
      const credentials = Realm.Credentials.anonymous();
      const user = await app.logIn(credentials);
      const mongo = user.mongoClient("mongodb-atlas");
      const collection = mongo.db("bharatsoft").collection("companymap");

      const formDataToSend = {
        ...formData,
        createdAt: new Date(),
      };

      await collection.insertOne(formDataToSend);

      toast.success("Data submitted successfully!");

      setTimeout(() => {
        window.location.reload(); // Reload page after 1 second
      }, 1000);
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("Failed to submit data.");
    } finally {
      setIsSubmitting(false); // Re-enable submit button
    }
  };
  return (
    <>
      <Helmet>
        <title>New Company - Bharat Soft Supplier</title>
        <meta name="description" content="Bharat Soft Supplier" />
      </Helmet>
      <Navbar heading="Add Company In Map" backto="/dashboard" />
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center mt-3">
            <h2 style={{ color: "red" }}>NEW COMPANY FORM</h2>
          </div>
        </div>
        <form
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          className="mb-5 pb-5"
        >
          {/* FORM SECTION 1 */}
          <div className="row">
            <div className="col-md-12">
              <h3>
                <u>Display Card Details</u>
              </h3>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Company Name*</label>
                <input
                  type="text"
                  className="form-control"
                  name="companyName"
                  placeholder="Enter Company Name"
                  value={formData.companyName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">
                  Upload Company Logo (400px / 250px)*
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="image"
                  onChange={handleImageChange}
                  accept="image/*"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Company Website Link*</label>
                <input
                  type="text"
                  name="visitWebsite"
                  className="form-control"
                  placeholder="Enter Valid Website Link"
                  value={formData.visitWebsite}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Average Project Size*</label>
                <input
                  type="text"
                  className="form-control"
                  name="avgProjectSize"
                  placeholder="Enter Average Project Size"
                  value={formData.avgProjectSize}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Projects Completed*</label>
                <input
                  type="text"
                  className="form-control"
                  name="projectCompleted"
                  placeholder="Number of Projects Completed"
                  value={formData.projectCompleted}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Company Size*</label>
                <input
                  type="text"
                  className="form-control"
                  name="companySize"
                  placeholder="Small/Medium/Large"
                  value={formData.companySize}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          {/* FORM SECTION 2 */}

          <div className="row mt-3">
            <div className="col-md-12">
              <h3>
                <u>Page Details</u>
              </h3>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Company Location*</label>
                <input
                  type="text"
                  name="location"
                  className="form-control"
                  placeholder="City and Country Name"
                  value={formData.location}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">
                  Remote Collaboration Possible?*
                </label>
                <select
                  name="remoteCollaborationPossible"
                  value={formData.remoteCollaborationPossible}
                  onChange={handleInputChange}
                  className="form-control"
                >
                  <option value="NA">Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
              <div className="mb-3">
                <label className="form-label">Development Process*</label>
                <input
                  type="text"
                  name="developmentProcess"
                  className="form-control"
                  placeholder="Explain the Process"
                  value={formData.developmentProcess}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">
                  Company Images (upto 3 Images)*
                </label>
                <input
                  type="file"
                  name="companyImages"
                  className="form-control"
                  multiple
                  onChange={handleCompanyImagesChange}
                  accept="image/*"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">
                  Languages/Software/Tools used by Company*
                </label>
                <input
                  type="text"
                  name="languages"
                  className="form-control"
                  placeholder="Example: React,MongoDB (comma separated)"
                  value={formData.languages.join(",")}
                  onChange={handleLanguagesChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">About Company*</label>
                <input
                  type="text"
                  name="aboutCompany"
                  className="form-control"
                  placeholder="About Company & Details"
                  value={formData.aboutCompany}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Company Age*</label>
                <input
                  type="text"
                  name="companyPage"
                  className="form-control"
                  placeholder="Company Age in Years"
                  value={formData.companyPage}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Methodologies*</label>
                <input
                  type="text"
                  name="methodologies"
                  className="form-control"
                  placeholder="Example: Agile,Scrum (comma separated)"
                  value={formData.methodologies.join(",")}
                  onChange={handleMethodologiesChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Company Work Tags*</label>
                <input
                  type="text"
                  name="workTags"
                  className="form-control"
                  placeholder="Example: E-Commerce,Technology (comma separated)"
                  value={formData.workTags.join(",")}
                  onChange={handleWorkTagsChange}
                />
              </div>
            </div>
          </div>

          {/* SECTION 3 */}

          <div className="row mt-3">
            <div className="col-md-12">
              <h3>
                <u>Contact Details</u>
              </h3>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Contact Person Name*</label>
                <input
                  type="text"
                  name="contactPersonName"
                  className="form-control"
                  placeholder="Contact Person Name"
                  value={formData.contactPersonName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Company Contact Number*</label>
                <input
                  type="text"
                  name="phoneNumber"
                  className="form-control"
                  placeholder="Phone Number"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Contact Person Image*</label>
                <input
                  type="file"
                  name="contactPersonImage"
                  className="form-control"
                  onChange={handleContactPersonImageChange}
                  accept="image/*"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Ideal Clients*</label>
                <input
                  type="text"
                  name="idealClients"
                  className="form-control"
                  placeholder="Ideal Clients"
                  value={formData.idealClients}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit Data"}
          </button>
        </form>
      </div>
      <ToastContainer />
    </>
  );
}
