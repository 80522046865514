import React from "react";
import { Link } from "react-router-dom";

export default function Main(props) {
  const handleLogout = () => {
    localStorage.removeItem("jwtToken");
    window.location.href = "/";
  };

  return (
    <>
      <div className="container-fluid bg-dark text-white">
        <div className="row pt-2 pb-2">
          <div className="col-md-11">
            <span style={{ fontSize: "24px" }}>
              {props.backto && (
                <Link
                  to={props.backto}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  &larr;&nbsp;
                </Link>
              )}
            </span>
            <span className="mt-1" style={{ fontSize: "24px" }}>
              {props.heading}
            </span>
          </div>
          <div className="col-md-1">
            <button onClick={handleLogout} className="btn btn-danger">
              Logout
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
