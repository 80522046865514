import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/main";
import * as Realm from "realm-web";
import { Helmet } from "react-helmet";

export default function UpdateCompany() {
  const [companyData, setCompanyData] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null); // Store the selected company
  const [updatedCompany, setUpdatedCompany] = useState({}); // Store the updated company data

  useEffect(() => {
    async function fetchData() {
      const app = new Realm.App({ id: "application-0-dlhopxm" });
      const credentials = Realm.Credentials.anonymous();
      try {
        const user = await app.logIn(credentials);
        const mongodb = app.currentUser.mongoClient("mongodb-atlas");
        const collection = mongodb.db("bharatsoft").collection("companymap");
        const data = await collection.find({});
        setCompanyData(data);
      } catch (err) {
        console.error("Failed to fetch data:", err);
      }
    }

    fetchData();
  }, []);

  // Handle Update Details button click
  const handleUpdateDetails = (company) => {
    setSelectedCompany(company); // Set selected company to prefill form
    setUpdatedCompany(company); // Prefill the form with current company details
    const modal = new window.bootstrap.Modal(
      document.getElementById("updateModal")
    );
    modal.show();
  };

  // Handle input change for the form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedCompany({ ...updatedCompany, [name]: value });
  };

  // Handle form submission for updating details
  const handleUpdateSubmit = async () => {
    const app = new Realm.App({ id: "application-0-dlhopxm" });
    const credentials = Realm.Credentials.anonymous();
    try {
      const user = await app.logIn(credentials);
      const mongodb = app.currentUser.mongoClient("mongodb-atlas");
      const collection = mongodb.db("bharatsoft").collection("companymap");

      // Update the company record in MongoDB
      await collection.updateOne(
        { _id: selectedCompany._id }, // Match by company ID
        { $set: updatedCompany } // Update the document
      );

      alert("Company details updated successfully!");
      window.location.reload(); // Refresh page to see updated data
    } catch (err) {
      console.error("Failed to update company details:", err);
    }
  };

  return (
    <>
      <Helmet>
        <title>Update Company Details - Bharat Soft Supplier</title>
        <meta name="description" content="Bharat Soft Supplier" />
      </Helmet>
      <Navbar heading="Update Company Details" backto="/dashboard" />
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center mt-3">
            <h2 style={{ color: "red" }}>COMPANY MAP LIST</h2>
            <div className="container">
              <div className="row card-container">
                {companyData ? (
                  companyData.map((company, index) => (
                    <div
                      className="col-md-4 d-flex align-items-stretch"
                      key={index}
                    >
                      <div className="card" style={{ margin: "10px" }}>
                        <img
                          src={company.image}
                          className="card-img-top"
                          alt="Company_Logo_Image"
                          style={{
                            height: "150px",
                            width: "320px",
                          }}
                        />
                        <div className="card-body">
                          <h5 className="card-title">{company.companyName}</h5>
                          <p className="card-text">
                            <strong>Company Size:</strong> {company.companySize}
                          </p>
                          <p className="card-text">
                            <strong>Average Project Size:</strong>{" "}
                            {company.avgProjectSize}
                          </p>
                          <p className="card-text">
                            <strong>Projects completed:</strong>{" "}
                            {company.projectCompleted}
                          </p>
                          <button
                            className="btn btn-primary"
                            onClick={() => handleUpdateDetails(company)}
                          >
                            Update Details
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Fetching Data from Database...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for updating company details */}
      <div
        className="modal fade"
        id="updateModal"
        tabIndex="-1"
        aria-labelledby="updateModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="updateModalLabel">
                Update {selectedCompany?.companyName}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {selectedCompany && (
                <form>
                  {/* Company Name */}
                  <div className="mb-3">
                    <label className="form-label">Company Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="companyName"
                      value={updatedCompany.companyName || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* Avg Project Size */}
                  <div className="mb-3">
                    <label className="form-label">Avg Project Size</label>
                    <input
                      type="text"
                      className="form-control"
                      name="avgProjectSize"
                      value={updatedCompany.avgProjectSize || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* Projects Completed */}
                  <div className="mb-3">
                    <label className="form-label">Projects Completed</label>
                    <input
                      type="text"
                      className="form-control"
                      name="projectCompleted"
                      value={updatedCompany.projectCompleted || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* Company Size */}
                  <div className="mb-3">
                    <label className="form-label">Company Size</label>
                    <input
                      type="text"
                      className="form-control"
                      name="companySize"
                      value={updatedCompany.companySize || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* Visit Website */}
                  <div className="mb-3">
                    <label className="form-label">Visit Website</label>
                    <input
                      type="text"
                      className="form-control"
                      name="visitWebsite"
                      value={updatedCompany.visitWebsite || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* Location */}
                  <div className="mb-3">
                    <label className="form-label">Location</label>
                    <input
                      type="text"
                      className="form-control"
                      name="location"
                      value={updatedCompany.location || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* About Company */}
                  <div className="mb-3">
                    <label className="form-label">About Company</label>
                    <textarea
                      className="form-control"
                      name="aboutCompany"
                      value={updatedCompany.aboutCompany || ""}
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                  {/* Remote Collaboration */}
                  <div className="mb-3">
                    <label className="form-label">
                      Remote Collaboration Possible
                    </label>
                    <select
                      className="form-control"
                      name="remoteCollaborationPossible"
                      value={updatedCompany.remoteCollaborationPossible || ""}
                      onChange={handleInputChange}
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  {/* Company Page */}
                  <div className="mb-3">
                    <label className="form-label">Company Page</label>
                    <input
                      type="text"
                      className="form-control"
                      name="companyPage"
                      value={updatedCompany.companyPage || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* Development Process */}
                  <div className="mb-3">
                    <label className="form-label">Development Process</label>
                    <input
                      type="text"
                      className="form-control"
                      name="developmentProcess"
                      value={updatedCompany.developmentProcess || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* Methodologies */}
                  <div className="mb-3">
                    <label className="form-label">Methodologies</label>
                    <input
                      type="text"
                      className="form-control"
                      name="methodologies"
                      value={updatedCompany.methodologies || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* Languages */}
                  <div className="mb-3">
                    <label className="form-label">Languages</label>
                    <input
                      type="text"
                      className="form-control"
                      name="languages"
                      value={updatedCompany.languages || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* Work Tags */}
                  <div className="mb-3">
                    <label className="form-label">Work Tags</label>
                    <input
                      type="text"
                      className="form-control"
                      name="workTags"
                      value={updatedCompany.workTags || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* Ideal Clients */}
                  <div className="mb-3">
                    <label className="form-label">Ideal Clients</label>
                    <input
                      type="text"
                      className="form-control"
                      name="idealClients"
                      value={updatedCompany.idealClients || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* Contact Person Name */}
                  <div className="mb-3">
                    <label className="form-label">Contact Person Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="contactPersonName"
                      value={updatedCompany.contactPersonName || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* Contact Person Image */}
                  <div className="mb-3">
                    <label className="form-label">Contact Person Image</label>
                    <input
                      type="file"
                      className="form-control"
                      name="contactPersonImage"
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* Phone Number */}
                  <div className="mb-3">
                    <label className="form-label">Phone Number</label>
                    <input
                      type="text"
                      className="form-control"
                      name="phoneNumber"
                      value={updatedCompany.phoneNumber || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* Company Images */}
                  <div className="mb-3">
                    <label className="form-label">Company Images</label>
                    <input
                      type="file"
                      className="form-control"
                      name="companyImages"
                      onChange={handleInputChange}
                      multiple
                    />
                  </div>
                  {/* Created At */}
                  <div className="mb-3">
                    <label className="form-label">Created At</label>
                    <input
                      type="date"
                      className="form-control"
                      name="createdAt"
                      value={updatedCompany.createdAt || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                </form>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleUpdateSubmit}
              >
                Update Details
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
