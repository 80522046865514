import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/main";
import * as Realm from "realm-web";
import { Helmet } from "react-helmet";

export default function ViewBlogs() {
  const [blogData, setBlogData] = useState(null); // Store blog data
  const [selectedBlog, setSelectedBlog] = useState(null); // Store selected blog for modal

  useEffect(() => {
    async function fetchData() {
      const app = new Realm.App({ id: "application-0-dlhopxm" });
      const credentials = Realm.Credentials.anonymous();
      try {
        const user = await app.logIn(credentials);
        const mongodb = app.currentUser.mongoClient("mongodb-atlas");
        const collection = mongodb.db("bharatsoft").collection("blog");
        const data = await collection.find({});
        setBlogData(data); // Set blog data to state
      } catch (err) {
        console.error("Failed to fetch blog data:", err);
      }
    }

    fetchData();
  }, []);

  // Function to handle "View Details" click
  const handleViewDetails = (blog) => {
    setSelectedBlog(blog); // Set the selected blog details
    // Show modal via Bootstrap JS
    const modal = new window.bootstrap.Modal(
      document.getElementById("blogModal")
    );
    modal.show();
  };

  return (
    <>
      <Helmet>
        <title>View Blogs - Bharat Soft Supplier</title>
        <meta name="description" content="View Blogs on Bharat Soft Supplier" />
      </Helmet>
      <Navbar heading="View Blogs" backto="/dashboard" />
      <div className="container mb-5 pb-4">
        <div className="row">
          <div className="col-md-12 text-center mt-3">
            <h2 style={{ color: "red" }}>BLOG LIST</h2>
            <div className="container">
              <div className="row card-container">
                {blogData ? (
                  blogData.map((blog, index) => (
                    <div
                      className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch"
                      key={index}
                    >
                      <div className="card w-100" style={{ margin: "10px" }}>
                        <img
                          src={blog.blogImage}
                          className="card-img-top img-fluid"
                          alt="Blog_Image"
                          style={{
                            height: "200px",
                            objectFit: "cover",
                          }}
                        />
                        <div className="card-body">
                          <h5 className="card-title">{blog.blogHeading}</h5>
                          <button
                            className="btn btn-primary"
                            onClick={() => handleViewDetails(blog)}
                          >
                            View Details
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Fetching Blogs from Database...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for displaying blog details */}
      <div
        className="modal fade"
        id="blogModal"
        tabIndex="-1"
        aria-labelledby="blogModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="blogModalLabel">
                {selectedBlog?.blogHeading}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {selectedBlog && (
                <>
                  <img
                    src={selectedBlog.blogImage}
                    alt="Blog_Image"
                    style={{ width: "100%", marginBottom: "20px" }}
                  />
                  <p>
                    <strong>Date: </strong>
                    {new Date(selectedBlog.date).toLocaleDateString()}
                  </p>
                  <p>
                    <strong>Author: </strong>
                    {selectedBlog.author}
                  </p>
                  <p>
                    <strong>Content: </strong>
                    {selectedBlog.blogContent}
                  </p>
                </>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
