import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LoginPage from "./LoginPage";
import Dashboard from "./Dashboard";
import Footer from "./components/Footer/main";
import ProtectedRoute from "./ProtectedRoute";
import AddCompany from "./components/CompanyMap/AddCompany";
import ViewCompany from "./components/CompanyMap/viewCompany";
import DeleteCompany from "./components/CompanyMap/DeleteCompany";
import UpdateCompany from "./components/CompanyMap/UpdateCompany";

import AddBlog from "./components/Blog/AddBlog";
import ViewBlog from "./components/Blog/ViewBlog";
import DeleteBlog from "./components/Blog/DeleteBlog";

import ContactPage from "./components/Contact/main";

import { Helmet } from "react-helmet";

import TopLoader from "nextjs-toploader";

function App() {
  return (
    <Router>
      <Helmet>
        <title>Admin Panel - Bharat Soft Supplier</title>
        <meta name="description" content="Bharat Soft Supplier" />
      </Helmet>
      <TopLoader color="#E92227" showSpinner={false} height={4} />
      <Routes>
        {/* Public route for login */}
        <Route path="/" element={<LoginPage />} />

        {/* Protected route for dashboard */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-new-company-in-map"
          element={
            <ProtectedRoute>
              <AddCompany />
            </ProtectedRoute>
          }
        />
        <Route
          path="/view-company-map"
          element={
            <ProtectedRoute>
              <ViewCompany />
            </ProtectedRoute>
          }
        />
        <Route
          path="/delete-company-map"
          element={
            <ProtectedRoute>
              <DeleteCompany />
            </ProtectedRoute>
          }
        />
        <Route
          path="/update-company-map"
          element={
            <ProtectedRoute>
              <UpdateCompany />
            </ProtectedRoute>
          }
        />

        <Route
          path="/add-new-blog"
          element={
            <ProtectedRoute>
              <AddBlog />
            </ProtectedRoute>
          }
        />
        <Route
          path="/view-blogs"
          element={
            <ProtectedRoute>
              <ViewBlog />
            </ProtectedRoute>
          }
        />
        <Route
          path="/delete-blog"
          element={
            <ProtectedRoute>
              <DeleteBlog />
            </ProtectedRoute>
          }
        />
        <Route
          path="/view-contact-responses"
          element={
            <ProtectedRoute>
              <ContactPage />
            </ProtectedRoute>
          }
        />

        {/* Redirect to login if route not found */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
