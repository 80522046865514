import React, { useState, useEffect } from "react";
import Navbar from "../Navbar/main";
import * as Realm from "realm-web";

export default function Main() {
  const [responses, setResponses] = useState([]);
  const [selectedResponse, setSelectedResponse] = useState(null);
  const [show, setShow] = useState(false);

  // Fetch data from MongoDB Realm
  useEffect(() => {
    const fetchData = async () => {
      const app = new Realm.App({ id: "application-0-dlhopxm" });
      const credentials = Realm.Credentials.anonymous();
      try {
        const user = await app.logIn(credentials);
        const mongo = user.mongoClient("mongodb-atlas");
        const collection = mongo.db("bharatsoft").collection("contactform");
        const data = await collection.find();
        setResponses(data);
        console.log(data);
      } catch (err) {
        console.error("Failed to fetch data", err);
      }
    };
    fetchData();
  }, []);

  // Handle opening the modal
  const handleShow = (response) => {
    setSelectedResponse(response);
    setShow(true);
  };

  // Handle closing the modal
  const handleClose = () => setShow(false);

  return (
    <>
      <Navbar heading="Contact Responses" backto="/dashboard" />
      <div className="container mt-5">
        <div className="row">
          {responses.map((response) => (
            <div key={response._id} className="col-md-12 mb-3">
              <div className="card">
                <div className="card-body d-flex justify-content-between align-items-center">
                  <div>
                    <h5 className="card-title">
                      {response.firstName} {response.lastName}
                    </h5>
                    <p className="card-text">
                      Date: {new Date(response.createdAt).toLocaleDateString()}
                    </p>
                  </div>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleShow(response)}
                  >
                    View Details
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal for viewing details */}
      {selectedResponse && (
        <div
          className={`modal ${show ? "show" : ""}`}
          style={{ display: show ? "block" : "none" }}
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {selectedResponse.firstName} {selectedResponse.lastName}
                </h5>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={handleClose}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  <strong>First Name:</strong> {selectedResponse.firstName}
                </p>
                <p>
                  <strong>Last Name:</strong> {selectedResponse.lastName}
                </p>
                <p>
                  <strong>Phone Number:</strong> {selectedResponse.phoneNumber}
                </p>
                <p>
                  <strong>Email:</strong> {selectedResponse.email}
                </p>
                <p>
                  <strong>Company Name:</strong> {selectedResponse.companyName}
                </p>
                <p>
                  <strong>Digital Scope:</strong>{" "}
                  {selectedResponse.digitalScope}
                </p>
                <p>
                  <strong>Project Objective:</strong>{" "}
                  {selectedResponse.projectObjective}
                </p>
                <p>
                  <strong>Primary Challenges:</strong>{" "}
                  {selectedResponse.primaryChallenges}
                </p>
                <p>
                  <strong>Budget Allocation:</strong>{" "}
                  {selectedResponse.budgetAllocation}
                </p>
                <p>
                  <strong>Brand Book:</strong> {selectedResponse.brandBook}
                </p>
                <p>
                  <strong>UI/UX:</strong> {selectedResponse.uiUx}
                </p>
                <p>
                  <strong>Communication:</strong>{" "}
                  {selectedResponse.communication}
                </p>
                <p>
                  <strong>Additional Info:</strong>{" "}
                  {selectedResponse.additionalInfo}
                </p>
                <p>
                  <strong>Additional Info Text:</strong>{" "}
                  {selectedResponse.additionalInfoText}
                </p>
                <p>
                  <strong>Methodology:</strong> {selectedResponse.methodology}
                </p>
                <p>
                  <strong>Integration:</strong> {selectedResponse.integration}
                </p>
                <p>
                  <strong>Compliance:</strong> {selectedResponse.compliance}
                </p>
                <p>
                  <strong>Security:</strong> {selectedResponse.security}
                </p>
                <p>
                  <strong>Scalability:</strong> {selectedResponse.scalability}
                </p>
                <p>
                  <strong>Duration:</strong> {selectedResponse.duration}
                </p>
                <p>
                  <strong>Deploy:</strong> {selectedResponse.deploy}
                </p>
                <p>
                  <strong>Date:</strong>{" "}
                  {new Date(selectedResponse.createdAt).toLocaleDateString()}
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
