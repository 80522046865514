import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/main";
import * as Realm from "realm-web";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function DeleteBlog() {
  const [blogData, setBlogData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const app = new Realm.App({ id: "application-0-dlhopxm" });
      const credentials = Realm.Credentials.anonymous();
      try {
        const user = await app.logIn(credentials);
        const mongodb = app.currentUser.mongoClient("mongodb-atlas");
        const collection = mongodb.db("bharatsoft").collection("blog");
        const data = await collection.find({});
        setBlogData(data);
      } catch (err) {
        console.error("Failed to fetch data:", err);
      }
    }

    fetchData();
  }, []);

  // Function to handle the deletion of a blog
  const handleDelete = async (blogId) => {
    const app = new Realm.App({ id: "application-0-dlhopxm" });
    const credentials = Realm.Credentials.anonymous();
    try {
      const user = await app.logIn(credentials);
      const mongodb = app.currentUser.mongoClient("mongodb-atlas");
      const collection = mongodb.db("bharatsoft").collection("blog");

      // Delete the blog document by its ID
      await collection.deleteOne({ _id: blogId });

      // Show success message
      toast.success("Blog deleted successfully!");

      // Update the list by removing the deleted blog
      setBlogData(blogData.filter((blog) => blog._id !== blogId));
    } catch (err) {
      console.error("Failed to delete blog:", err);
      toast.error("Failed to delete blog.");
    }
  };

  return (
    <>
      <Helmet>
        <title>Delete Blog - Bharat Soft Supplier</title>
        <meta name="description" content="Bharat Soft Supplier" />
      </Helmet>
      <Navbar heading="Delete Blog" backto="/dashboard" />
      <ToastContainer />
      <div className="container mb-5 pb-4">
        <div className="row">
          <div className="col-md-12 text-center mt-3">
            <h2 style={{ color: "red" }}>BLOG LIST FOR DELETION</h2>
            <div className="container">
              <div className="row card-container">
                {blogData ? (
                  blogData.map((blog, index) => (
                    <div
                      className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch"
                      key={index}
                    >
                      <div className="card w-100" style={{ margin: "10px" }}>
                        <img
                          src={blog.blogImage}
                          className="card-img-top img-fluid"
                          alt="Blog_Image"
                          style={{
                            height: "200px",
                            objectFit: "cover",
                          }}
                        />
                        <div className="card-body">
                          <h5 className="card-title">{blog.blogHeading}</h5>
                          <button
                            className="btn btn-danger"
                            onClick={() => handleDelete(blog._id)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Fetching Data from Database...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
