import React, { useState } from "react";
import Navbar from "../Navbar/main";
import * as Realm from "realm-web";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

export default function AddBlog() {
  const [formData, setFormData] = useState({
    blogImage: null,
    blogHeading: "",
    date: "",
    author: "",
    blogContent: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false); // To disable submit button

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle blog image change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData({ ...formData, blogImage: reader.result });
    };
    reader.readAsDataURL(file);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable submit button

    try {
      const app = new Realm.App({ id: "application-0-dlhopxm" });
      const credentials = Realm.Credentials.anonymous();
      const user = await app.logIn(credentials);
      const mongo = user.mongoClient("mongodb-atlas");
      const collection = mongo.db("bharatsoft").collection("blog");

      const formDataToSend = {
        ...formData,
        createdAt: new Date(),
      };

      await collection.insertOne(formDataToSend);

      toast.success("Blog submitted successfully!");

      setTimeout(() => {
        window.location.reload(); // Reload page after 1 second
      }, 1000);
    } catch (error) {
      console.error("Error submitting blog:", error);
      toast.error("Failed to submit blog.");
    } finally {
      setIsSubmitting(false); // Re-enable submit button
    }
  };

  return (
    <>
      <Helmet>
        <title>New Blog - Bharat Soft Supplier</title>
        <meta name="description" content="Bharat Soft Supplier Blog Entry" />
      </Helmet>
      <Navbar heading="Add New Blog" backto="/dashboard" />
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center mt-3">
            <h2 style={{ color: "red" }}>NEW BLOG ENTRY</h2>
          </div>
        </div>
        <form
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          className="mb-5 pb-5"
        >
          <div className="row mt-4">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Blog Heading*</label>
                <input
                  type="text"
                  className="form-control"
                  name="blogHeading"
                  placeholder="Enter Blog Heading"
                  value={formData.blogHeading}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Author*</label>
                <input
                  type="text"
                  className="form-control"
                  name="author"
                  placeholder="Enter Author Name"
                  value={formData.author}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">
                  Upload Blog Image (800px x 600px)*
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="blogImage"
                  onChange={handleImageChange}
                  accept="image/*"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Date*</label>
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  value={formData.date}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Blog Content*</label>
                <textarea
                  className="form-control"
                  name="blogContent"
                  rows="8"
                  placeholder="Write your blog content here..."
                  value={formData.blogContent}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-primary"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit Blog"}
          </button>
        </form>
      </div>
      <ToastContainer />
    </>
  );
}
