import React from "react";
import DashComp from "./components/Dashboard/main";
import Navbar from "./components/Navbar/main";
import { Helmet } from "react-helmet";

const Dashboard = () => {
  return (
    <>
      <Helmet>
        <title>Dashboard - Bharat Soft Supplier</title>
        <meta name="description" content="Bharat Soft Supplier" />
      </Helmet>
      <div>
        <Navbar heading="Bharat Soft Supplier" backto="" />
        <DashComp />
      </div>
    </>
  );
};

export default Dashboard;
