import React from "react";

export default function main() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer
        className="text-center text-lg-start fixed-bottom shadow-lg"
        style={{ backgroundColor: "#D2E0FB" }}
      >
        <div className="container-fluid pb-2 mt-3">
          <div className="row">
            <div className="col-md-6">
              &copy; {currentYear} <b>Bharat Soft Supplier</b>
            </div>
            <div className="col-md-6" style={{ textAlign: "right" }}>
              Designed and Developed by{" "}
              <a
                href="https://github.com/gagan257"
                target="_blank"
                rel="noreferrer"
              >
                Gagan Arora
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
