import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/main";
import * as Realm from "realm-web";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function DeleteCompany() {
  const [companyData, setCompanyData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const app = new Realm.App({ id: "application-0-dlhopxm" });
      const credentials = Realm.Credentials.anonymous();
      try {
        const user = await app.logIn(credentials);
        const mongodb = app.currentUser.mongoClient("mongodb-atlas");
        const collection = mongodb.db("bharatsoft").collection("companymap");
        const data = await collection.find({});
        setCompanyData(data);
      } catch (err) {
        console.error("Failed to fetch data:", err);
      }
    }

    fetchData();
  }, []);

  // Function to handle the deletion of a company
  const handleDelete = async (companyId) => {
    const app = new Realm.App({ id: "application-0-dlhopxm" });
    const credentials = Realm.Credentials.anonymous();
    try {
      const user = await app.logIn(credentials);
      const mongodb = app.currentUser.mongoClient("mongodb-atlas");
      const collection = mongodb.db("bharatsoft").collection("companymap");

      // Delete the company document by its ID
      await collection.deleteOne({ _id: companyId });

      // Show success message
      toast.success("Company deleted successfully!");

      // Update the list by removing the deleted company
      setCompanyData(
        companyData.filter((company) => company._id !== companyId)
      );
    } catch (err) {
      console.error("Failed to delete company:", err);
      toast.error("Failed to delete company.");
    }
  };

  return (
    <>
      <Helmet>
        <title>Delete Company - Bharat Soft Supplier</title>
        <meta name="description" content="Bharat Soft Supplier" />
      </Helmet>
      <Navbar heading="Delete Company" backto="/dashboard" />
      <ToastContainer />
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center mt-3">
            <h2 style={{ color: "red" }}>COMPANY LIST FOR DELETION</h2>
            <div className="container">
              <div className="row card-container">
                {companyData ? (
                  companyData.map((company, index) => (
                    <div
                      className="col-md-4 d-flex align-items-stretch"
                      key={index}
                    >
                      <div
                        className="card"
                        style={{ margin: "10px", width: "320px" }}
                      >
                        <img
                          src={company.image}
                          className="card-img-top"
                          alt="Company_Logo_Image"
                          style={{ height: "150px", objectFit: "cover" }}
                        />
                        <div className="card-body">
                          <h5 className="card-title">{company.companyName}</h5>
                          {/* Company Size */}
                          <p className="card-text">
                            <strong>Company Size:</strong> {company.companySize}
                          </p>
                          <p className="card-text">
                            <strong>Average Project Size:</strong>{" "}
                            {company.avgProjectSize}
                          </p>
                          <p className="card-text">
                            <strong>Projects completed:</strong>{" "}
                            {company.projectCompleted}
                          </p>
                          <button
                            className="btn btn-danger"
                            onClick={() => handleDelete(company._id)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Fetching Data from Database...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
